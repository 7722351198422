/** @jsx jsx */
import { useMemo } from 'react'
// @ts-ignore
import { jsx, Box, Flex, Button } from 'theme-ui'
import { Logo } from '~/components/logo'
import { HeaderVariantType } from '~/@types/models'
import HeaderCartOpener from '~/components/header/header-cart-opener'
import { RiUserFill, RiUserLine } from '@remixicon/react'
import urls from '~/common/urls'
import { Link } from 'gatsby'
import { useCustomerContext } from '~/context/customer-context'
import { ICON_SIZE } from './constants'

type Props = {
  headerVariant: HeaderVariantType
  children?: React.ReactNode
  isScrolling?: boolean
  handleUserMenu: (open: boolean) => void
  isUserMenuOpen?: boolean
}

const MobileContent = ({
  headerVariant,
  children,
  isScrolling,
  handleUserMenu,
  isUserMenuOpen,
}: Props) => {
  const isDarkSolid = headerVariant === 'dark_solid'

  const { isLoggedIn } = useCustomerContext()

  const styleSettings = useMemo(() => {
    return {
      logo: {
        color: isDarkSolid ? 'main' : 'initial',
      },
      leftMenu: {
        isLight: (headerVariant === 'light' && !isScrolling) || (isDarkSolid && 'main'),
      },
    }
  }, [headerVariant, isScrolling])

  return (
    <Box
      className="mobile-content"
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr',
        alignItems: 'center',
        height: 64,
      }}
    >
      <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>{children}</Box>
      <Box
        sx={{
          width: '98%',
          margin: '0 auto',
        }}
      >
        <Logo color={styleSettings.logo.color} sxProps={{ width: '100%' }} />
      </Box>
      <Flex
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          gap: 12,
          justifyContent: 'flex-end',
        }}
      >
        <Link
          to={isLoggedIn ? urls.account.account : urls.account.signIn}
          className="user-icon"
          sx={{
            all: 'unset',
            textTransform: 'uppercase',
            fontWeight: 600,
            color: styleSettings.logo.color,
            cursor: 'pointer',
          }}
        >
          {isLoggedIn ? (
            <RiUserFill sx={{ width: ICON_SIZE }} />
          ) : (
            <RiUserLine sx={{ width: ICON_SIZE }} />
          )}
        </Link>
        <HeaderCartOpener sxProps={{ textTransform: 'uppercase' }} />
      </Flex>
    </Box>
  )
}

export default MobileContent
