/** @jsx jsx **/
import { jsx, Box, Close } from 'theme-ui'
import { FC, Fragment } from 'react'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import useHeaderScrolling from '~/hooks/use-header-scolling'
import '@reach/dialog/styles.css'
import CartOverlayHeader from './cart-overlay-header'

type Props = {
  title?: string
  showDialog: boolean
  close(): void
}

const CartOverlay: FC<Props> = ({ children, showDialog, close }) => {
  const { isScrolling } = useHeaderScrolling()
  return (
    <Fragment>
      <DialogOverlay
        sx={{
          zIndex: 1000,
          background: 'transparent',
        }}
        isOpen={showDialog}
        onDismiss={close}
      >
        <DialogContent
          aria-label="Cart"
          aria-describedby="cart"
          sx={{
            boxShadow: '0px 0 100px 20px hsla(0, 0%, 0%, 0.33)',
            p: 0,
            // width: ['calc(100% - 64px)', null, null, 700],
            width: ['100%', null, null, 700],
            maxWidth: '700px',
            display: 'flex',
            flexDirection: 'column',
            '@media(max-width: 374px)': {
              width: '100%',
            },
            m: [0],
            ml: ['auto'],
            // height: isScrolling ? ['calc(100% - 64px)', '100%'] : ['calc(100% - 120px)', '100%'],
            height: isScrolling ? ['calc(100% - 64px)', '100%'] : ['100%'],
            bottom: 0,
            position: 'fixed',
            right: '0',
          }}
        >
          <Box
            sx={{
              height: '64px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              pb: ['15px', null, '0'],
              px: '20px',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px solid #E6E6E6',
              gap: '20px',
              mb: '20px',
            }}
          >
            <CartOverlayHeader />
            <Box onClick={close} sx={{ cursor: 'pointer', display: ['block', null, 'none'] }}>
              <Close />
            </Box>
          </Box>
          {children}
        </DialogContent>
      </DialogOverlay>
      {!showDialog && (
        <Box
          sx={{
            display: `none`,
          }}
        >
          {children}
        </Box>
      )}
    </Fragment>
  )
}

export default CartOverlay
