import React, { FC, useState, useCallback } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, Configure, InstantSearchProps } from 'react-instantsearch-dom'
import { AutoComplete } from './autocomplete'

type Props = Partial<InstantSearchProps> & {
  initialSearchValue?: string
  configure?: object
}

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY as string
)
const _indexName = 'products'
const minSearchChars = 3

const InstantSearchWrapper: FC<Props> = ({
  indexName = _indexName,
  initialSearchValue = '',
  children,
  configure,
  ...rest
}) => {
  const [searchState, setSearchState] = useState<{
    query: string
  }>({
    query: initialSearchValue,
  })

  const searchHandler = useCallback(
    requests => {
      // @ts-ignore
      if (requests.every(({ params }) => !params.query || params.query.length < minSearchChars)) {
        // return empty results when empty query
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
          })),
        })
      }
      return client.search(requests)
    },
    [client]
  )

  const searchClient = {
    ...client,
    search: searchHandler,
  }

  return (
    <InstantSearch
      indexName={indexName}
      searchClient={searchClient}
      searchState={searchState}
      onSearchStateChange={nextSearchState => setSearchState(nextSearchState)}
      {...rest}
    >
      <Configure
        hitsPerPage={9}
        distinct
        typoTolerance={true}
        facetFilters={[['tags:-HiddenProduct']]}
        clickAnalytics={true}
        filters={
          "(NOT options.size:Swatch OR collections:custom-quick-ship) AND (inventory_available:true OR inventory_policy:'continue')"
        }
        {...configure}
      />
      {children}
    </InstantSearch>
  )
}

export default InstantSearchWrapper
