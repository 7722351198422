import React, { FC } from 'react'
import { useMenuContext } from './context'
import { IMedia } from '~/componentsV2/ResposiveImage'
import { Box, ThemeUIStyleObject } from 'theme-ui'
import MobileContent from '~/components/header/mobileContent'
import Search from '~/components/search'
import { useCustomerContext } from '~/context/customer-context'
import MobileMainMenu from '../v2/item'
import { Link } from 'gatsby'
import urls from '~/common/urls'
import { space } from '~/gatsby-plugin-theme-ui'
import { useSearchHandlers } from '~/components/header/use-search-handlers'

export interface IMenuItem {
  id: number
  name: string
  link?: string
  mobileImage?: IMedia
  menus: IMenuItem[]
}

const MenuComponent = ({ item }: { item: IMenuItem }) => {
  const { setCurrentMenu } = useMenuContext()

  if (!item?.link && !item?.menus.length) return null

  const isAnchor = item.link && !item.menus?.length
  const WrapperComponent = isAnchor ? 'a' : 'div'

  const handleClick = e => {
    e.preventDefault()
    e.stopPropagation()

    setCurrentMenu(item)
  }

  const compoProps = isAnchor ? { href: item.link } : { onClick: handleClick }

  return (
    <WrapperComponent style={{ all: 'unset', cursor: 'pointer' }} {...compoProps}>
      {item.mobileImage && (
        <img
          src={item.mobileImage.url}
          alt={item.name}
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
            aspectRatio: '1',
          }}
        />
      )}
      <p
        style={{
          textAlign: 'center',
          textTransform: 'uppercase',
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#595858',
        }}
      >
        {item?.name}
      </p>
    </WrapperComponent>
  )
}

const Arrow = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="14"
    viewBox="0 0 19 14"
    fill="none"
    {...props}
  >
    <path
      d="M17.3608 6.96777L1.36084 6.96777M1.36084 6.96777L7.36084 0.967774M1.36084 6.96777L7.36084 12.9678"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export const MenuMobileWithImages: FC = ({ children }) => {
  const { currentMenu, footerMenu, previousMenu, setCurrentMenu } = useMenuContext()
  const { isLoggedIn, customer } = useCustomerContext()

  const mappedMenu = footerMenu?.length
    ? footerMenu.map(item => <MobileMainMenu key={'menu' + item.id} item={item} />)
    : null

  const baseStyles: ThemeUIStyleObject = {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid',
    borderColor: 'border',
    width: '100%',
    padding: '12px',
    gap: '12px',
    color: '#595858',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    textTransform: 'uppercase',
    lineHeight: '31.5px',
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        bg: 'main',
        height: '100vh',
        overflowY: 'auto',
        width: '100%',
        top: '100%',
        pb: 200,
        left: 0,
        '.mobile-content': {
          svg: {
            fill: '#000',
          },
          button: {
            color: 'black',
          },
        },
      }}
    >
      <Box as="nav" className="menu">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
          }}
        >
          {previousMenu ? (
            <Box
              role="button"
              tabIndex={0}
              onClick={() => setCurrentMenu(previousMenu)}
              sx={baseStyles}
            >
              <Arrow fill="currentColor" />
              {previousMenu?.name || 'Back'}
            </Box>
          ) : null}
          {currentMenu?.name ? <Box sx={baseStyles}>{currentMenu?.name}</Box> : null}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '20px',
              padding: '10px',
              flex: 1,
            }}
          >
            {currentMenu?.menus.map(menu => (
              <MenuComponent key={menu.id} item={menu} />
            ))}
          </div>

          <Box
            sx={{
              width: '100%',
              '.menu-link': {
                color: 'zambezi',
                textDecoration: 'none',
                fontSize: ['12px', '14px', '16px'],
                border: '1px solid',
                borderColor: 'border',
                display: 'block',
                px: space.md2,
                py: 20,
                background: 'transparent',
                width: '100%',
                textAlign: 'left',
                mt: -1,
                ':hover': {
                  textDecoration: 'underline',
                  color: 'text',
                },
                ul: {
                  mergeLeft: 20,
                },
              },
              '.hasSubmenu .menu-link:hover': {
                textDecoration: 'none',
              },
              li: {
                position: 'relative',
              },
              ul: {
                variant: 'variants.list.none',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              },
              'ul ul .menu-link': {
                color: 'muted',
                ':hover': {
                  textDecoration: 'underline',
                  color: 'text',
                },
              },
              'ul ul': {
                display: 'none',
              },
              'ul ul > li > .menu-link': {
                pl: 40,
              },
              'ul ul ul > li > .menu-link': {
                pl: 60,
              },
              'ul ul ul ul > li > .menu-link': {
                pl: 80,
              },
            }}
          >
            {/* <ul>
              <li className="menu-link">
                <Link
                  style={{ all: 'unset', textTransform: 'uppercase', fontWeight: 600 }}
                  to={isLoggedIn ? urls.account.accountPersonalData : urls.account.signIn}
                  data-cy="account-link"
                >
                  {isLoggedIn && customer?.email ? `${customer?.email}` : 'Account'}
                </Link>
              </li>
              {mappedMenu}
            </ul> */}
          </Box>
        </div>
      </Box>
    </Box>
  )
}
