import { Box, SxStyleProp } from 'theme-ui'
import React from 'react'
import { useLocation } from '@reach/router'
import SearchInput from '~/components/search-input'
import { connectSearchBox } from 'react-instantsearch-dom'
import { SearchBoxProvided } from 'react-instantsearch-core'
import { ProductHit } from './types'
import { navigate } from 'gatsby'
import { H3, H5 } from '~/styled/components/typography'

type Props = SearchBoxProvided & {
  focusOnMount?: boolean
  sx?: SxStyleProp
  hitRef?: React.MutableRefObject<ProductHit[] | undefined>
  closeSearch?: (value: boolean) => void
}

const SearchBox = (props: Props) => {
  const { currentRefinement, refine, focusOnMount = true, sx, hitRef } = props

  const onSubmit = () => {
    let handle
    if (hitRef && hitRef?.current && hitRef.current.length === 1) {
      handle = hitRef.current[0].handle
    }
    navigate(handle ? `/products/${handle}` : `/search?search=${currentRefinement}`)
  }

  const { pathname } = useLocation()

  return (
    <>
      <SearchInput
        searchInputValue={currentRefinement}
        onEnterPress={onSubmit}
        onChange={event => {
          refine(event.currentTarget.value)
          pathname === '/search' && navigate(`/search?search=${event.currentTarget.value}`)
        }}
        // setSearchInputValue={() => refine(``)}
        focusOnMount={focusOnMount}
        closeSearch={props.closeSearch}
        css={sx}
      />
      {/* <Box
        sx={{
          aspectRatio: '100 / 20',
          position: 'relative',
          overflow: 'hidden',
          marginTop: '16px',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            zIndex: 3,
          }}
        >
          <H3>Black Friday Sale 2024</H3>
        </Box>
        <img
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          src="/media/2024/black-friday/banner.jpeg"
          alt="search icon"
        />
      </Box> */}
    </>
  )
}

export default connectSearchBox<Props>(SearchBox)
