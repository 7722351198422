/** @jsx jsx **/
// @ts-ignore
import { jsx, Flex, Grid, Box, Heading, Spinner } from 'theme-ui'
import { FC, createRef, useEffect } from 'react'
import { connectHits } from 'react-instantsearch-dom'
import SearchResultItem from '~/components/search-result-item'
import { Button } from '~/components/button'
import urls from '~/common/urls'
import StateResults from '~/features/instant-search/state-results'
import Hit from '~/features/instant-search/hit'
import { ProductHit } from '~/features/instant-search/types'
import { useCustomerContext } from '~/context/customer-context'
import algoliasearch from 'search-insights'

type Props = {
  hitRef: React.MutableRefObject<ProductHit[] | undefined>
}

const transformSearchData = (hit: ProductHit) => {
  const { product_image: image, handle, title, id } = hit
  return {
    name: title,
    image,
    handle: `${urls.pages.productDetail}/${handle}`,
    id: id,
  }
}

algoliasearch('init', {
  appId: process.env.GATSBY_ALGOLIA_APP_ID as string,
  apiKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY as string,
  useCookie: true,
})

// @ts-ignore
const SearchResults: FC<Props> = connectHits<ProductHit>(({ hits, hitRef }) => {
  const { auth } = useCustomerContext()

  algoliasearch('setUserToken', auth?.accessToken ?? 'user-id')
  const containerRef = createRef<HTMLDivElement>()

  useEffect(() => {
    if (hits) {
      hitRef.current = hits
    }
  }, [hits, hitRef])

  if (hits && hits.length > 0) {
    return (
      <StateResults>
        {/* @ts-ignore */}
        {({ searchState, hits: totalHits }) => (
          <Box
            className="ais-InfiniteHits"
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              height: [`calc(100vh - 214px)`, null, 'auto'],
            }}
          >
            <Grid
              className="ais-InfiniteHits-list"
              as="ul"
              columns={[1, 2, 3]}
              gap={['gridGap1', null, 'gridGap3']}
              sx={{
                listStyleType: 'none',
                flex: 1,
              }}
              ref={containerRef}
            >
              {hits.map((item, index) => {
                const data = transformSearchData(item)

                return (
                  <Hit
                    key={data.handle}
                    containerRef={containerRef}
                    as="li"
                    label={item.title}
                    aria-posinset={index + 1}
                    aria-setsize={totalHits}
                    // SearchResultItem is natively focusable
                    tabIndex={0}
                  >
                    {/* @ts-ignore */}
                    <SearchResultItem data={data} hit={item} queryID={item.__queryID} />
                  </Hit>
                )
              })}
            </Grid>
            {/* @ts-ignore */}
            <Flex
              sx={{
                justifyContent: 'center',
                pt: ['16px', null, 'l'],
                boxShadow: ['0 -1px 0 0 #99999980', null, 'none'],
                zIndex: 2,
              }}
            >
              <Button
                as="link"
                to={`${urls.pages.search}?search=${searchState.query}`}
                label="View All"
                sx={{
                  width: ['100%', null, 300],
                }}
              />
            </Flex>
          </Box>
        )}
      </StateResults>
    )
  }

  return (
    <Heading
      variant="title4"
      sx={{
        textAlign: 'center',
      }}
    >
      No Results
    </Heading>
  )
})

export default SearchResults
