/** @jsx jsx */
import { useCallback, useMemo, useState } from 'react'
// @ts-ignore
import { jsx, ThemeUIStyleObject } from 'theme-ui'
import { Hamburger } from '~/components/hamburger'
import useHeaderScrolling from '~/hooks/use-header-scolling'
import { HeaderVariantType } from '~/@types/models'
import HeaderWrapper from '~/components/header/header-wrapper'
import MobileContent from './mobileContent'
import { MenuMobileWithImages } from '../menu-mobile/withImages'
import { MenuMobileProvider } from '../menu-mobile/withImages/context'
import Affix from './Affix'
import { StyledHeaderWrapper } from './styles'
import { useSearchHandlers } from './use-search-handlers'
import Search from '../search'
import { RiSearchLine } from '@remixicon/react'
import { UserMenu } from './userMenu'
import { useOverlayContext } from '~/context/overlay-context'
import { ICON_SIZE } from './constants'

type Props = {
  headerVariant: HeaderVariantType
  sxProps?: ThemeUIStyleObject
}

const HeaderMobile = ({ headerVariant }: Props) => {
  const { isScrolling } = useHeaderScrolling()
  const { showSearch, toggleSearch } = useSearchHandlers()
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false)
  const isDarkSolid = headerVariant === 'dark_solid'
  const lighters = ['light', 'light_blue_gradient', 'transparent']
  const [activeAffix, setActiveAffix] = useState(false)

  const { toggleOverlay } = useOverlayContext()

  const currentHeaderVariant = useMemo(() => {
    if (isNavOpen || activeAffix || showSearch || isUserMenuOpen) return 'default'
    return headerVariant
  }, [headerVariant, isNavOpen, activeAffix, showSearch, isUserMenuOpen])

  const styleSettings = useMemo(() => {
    return {
      logo: {
        color: isDarkSolid ? 'main' : 'initial',
      },
      leftMenu: {
        isLight: lighters.includes(currentHeaderVariant) || (isDarkSolid && 'main'),
      },
    }
  }, [currentHeaderVariant, isScrolling])

  const handleSearch = useCallback(
    (search: boolean) => {
      toggleSearch(search)
      setIsNavOpen(false)
      setIsUserMenuOpen(false)
    },
    [toggleSearch, showSearch]
  )

  const handleUserMenu = useCallback(
    (open: boolean) => {
      toggleSearch(false)
      setIsNavOpen(false)
      setIsUserMenuOpen(open)
      toggleOverlay(open)
    },
    [setIsUserMenuOpen, isUserMenuOpen]
  )

  return (
    <MenuMobileProvider>
      <StyledHeaderWrapper>
        <Affix relativeElementSelector="#___gatsby" onAffixChange={setActiveAffix}>
          <HeaderWrapper
            display="MOBILE"
            headerVariant={currentHeaderVariant}
            sxProps={{
              padding: '0',
              '.search': {
                paddingBottom: '10px',
                display: 'flex',
                flexDirection: 'column',
              },
              '.mobile-content': {
                padding: '0 12px',
              },
              '.search-input-container': { padding: '0 12px', border: 'none' },
              '.search-input': {
                padding: '0',
                borderTop: '1px solid #E9E9E9',
                borderBottom: '1px solid #E9E9E9',
                background: '#F1F1F1',
                '&>div': {
                  padding: '0 10px',
                },
                svg: {
                  fill: '#9A9A9A',
                  path: {
                    fill: '#9A9A9A',
                    stroke: '#9A9A9A',
                  },
                },
                'input::placeholder': {
                  color: '#9A9A9A',
                },
              },
              'search-results': {
                flex: 1,
              },
              '.search-results ul': {
                paddingLeft: 0,
                height: 'calc(100vh - 262px)',
                overflow: 'auto',
              },
            }}
          >
            <MobileContent
              headerVariant={currentHeaderVariant}
              isScrolling={activeAffix}
              isUserMenuOpen={isUserMenuOpen}
              handleUserMenu={handleUserMenu}
            >
              <Hamburger
                isOpenNav={isNavOpen}
                setIsOpenNav={setIsNavOpen}
                isLight={styleSettings.leftMenu.isLight}
              />
              <RiSearchLine
                className="search-icon"
                onClick={e => {
                  e.stopPropagation()
                  handleSearch(!showSearch)
                }}
                sx={{ width: ICON_SIZE }}
                style={{ cursor: 'pointer' }}
              />
            </MobileContent>
            {isNavOpen ? <MenuMobileWithImages isScrolling={isScrolling} /> : null}
            {showSearch ? <Search toggleSearch={handleSearch} /> : null}
            {/* {isUserMenuOpen ? <UserMenu handleUserMenu={handleUserMenu} /> : null} */}
          </HeaderWrapper>
        </Affix>
      </StyledHeaderWrapper>
    </MenuMobileProvider>
  )
}

export default HeaderMobile
