import React, { useState, useEffect, useRef, ReactNode, CSSProperties } from 'react'

export interface AffixProps {
  enabled?: boolean
  relativeElementSelector?: string
  fixedNavbarSelector?: string
  fixedFooterSelector?: string
  topOffset?: number
  bottomOffset?: number
  inheritParentWidth?: boolean
  children?: ReactNode
  onAffixChange?: (isAffixed: boolean) => void
}

const Affix = (props: AffixProps) => {
  const {
    enabled = true,
    relativeElementSelector = '',
    fixedNavbarSelector = '',
    fixedFooterSelector = '',
    topOffset = 15,
    bottomOffset = 15,
    inheritParentWidth = true,
    children,
    onAffixChange,
  } = props

  const [affixStyle, setAffixStyle] = useState<CSSProperties>({})
  const [isAffixed, setIsAffixed] = useState(false)
  const rootRef = useRef<HTMLDivElement>(null)
  const affixRef = useRef<HTMLDivElement>(null)
  const prevWindowScrollYRef = useRef<number>(typeof window !== 'undefined' ? window.scrollY : 0)

  const rootElement = rootRef.current

  const queryElement = (selector: string): HTMLElement | null => {
    if (selector && typeof document !== 'undefined') {
      return document.querySelector<HTMLElement>(selector)
    }
    return null
  }

  const isElementVisible = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect()
    return rect.width > 0 || rect.height > 0
  }

  const computeStyle = (): CSSProperties => {
    const _style: CSSProperties = {}
    if (typeof window === 'undefined') return _style

    const relative = queryElement(relativeElementSelector) || rootRef.current?.parentElement
    const navbar = queryElement(fixedNavbarSelector)
    const footer = queryElement(fixedFooterSelector)

    if (!rootRef.current || !affixRef.current || !relative || !enabled) return _style
    if (
      !isElementVisible(relative) ||
      !isElementVisible(rootRef.current) ||
      !isElementVisible(affixRef.current)
    )
      return _style

    const relativeRect = relative.getBoundingClientRect()
    const rootRect = rootRef.current.getBoundingClientRect()
    const affixRect = affixRef.current.getBoundingClientRect()

    const scrollY = window.scrollY
    const prevScrollY = prevWindowScrollYRef.current

    const topSpace = topOffset + (navbar?.clientHeight || 0) + (navbar?.offsetTop || 0)
    const bottomSpace = bottomOffset + (footer?.clientHeight || 0)
    const contentHeight = affixRef.current.clientHeight
    const affixHeight = topSpace + contentHeight + bottomSpace

    const floatStartPoint = scrollY + rootRect.top
    const floatEndPoint = scrollY + relativeRect.bottom
    const floatSpace = floatEndPoint - floatStartPoint
    const canFloat = floatSpace > affixHeight

    const scrollingUp = prevScrollY > scrollY
    const scrollingDown = prevScrollY < scrollY
    const scrollDistance = Math.abs(scrollY - prevScrollY)

    const bottomPosition = Math.ceil(floatEndPoint - (scrollY + contentHeight))

    if (scrollY + topSpace > floatStartPoint && canFloat) {
      _style.position = 'fixed'
      _style.top = topSpace

      if (onAffixChange) {
        onAffixChange(true)
      }
      setIsAffixed(true)

      if (inheritParentWidth) _style.width = `${rootRef.current.clientWidth}px`

      if (scrollY + topSpace + contentHeight >= floatEndPoint) _style.top = `${bottomPosition}px`

      if (affixHeight > window.innerHeight) {
        if (scrollY + affixRect.bottom >= floatEndPoint) _style.top = `${bottomPosition}px`
        else if (scrollingDown)
          _style.top = `${Math.max(
            affixRect.top - scrollDistance,
            window.innerHeight - contentHeight - bottomSpace
          )}px`
        else if (scrollingUp) _style.top = `${Math.min(affixRect.top + scrollDistance, topSpace)}px`
      }
    } else {
      if (onAffixChange) {
        onAffixChange(false)
      }
      setIsAffixed(false)
    }

    return _style
  }

  useEffect(() => {
    if (typeof window === 'undefined' || !enabled) return

    const scrollHandler = () => {
      setAffixStyle(computeStyle())
      prevWindowScrollYRef.current = window.scrollY
    }

    setAffixStyle(computeStyle())
    window.addEventListener('scroll', scrollHandler)
    window.addEventListener('resize', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
      window.removeEventListener('resize', scrollHandler)
    }
  }, [
    enabled,
    relativeElementSelector,
    fixedNavbarSelector,
    fixedFooterSelector,
    topOffset,
    bottomOffset,
    inheritParentWidth,
  ])

  useEffect(() => {
    if (rootElement && typeof ResizeObserver !== 'undefined' && inheritParentWidth) {
      let width: number | null = null

      const observer = new ResizeObserver(() => {
        if (width !== null && width !== rootElement.clientWidth) {
          setAffixStyle(computeStyle())
          prevWindowScrollYRef.current = window.scrollY
        }
        width = rootElement.clientWidth
      })

      observer.observe(rootElement)
      return () => observer.disconnect()
    }

    return () => null
  }, [rootElement])

  return (
    <div ref={rootRef} style={{ width: '100%' }}>
      <div ref={affixRef} style={affixStyle} className={`affix ${isAffixed ? 'affix-active' : ''}`}>
        {children}
      </div>
    </div>
  )
}

export default Affix
