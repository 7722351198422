/** @jsx jsx */
import { FC, useCallback, MouseEvent } from 'react'
import { Link } from 'gatsby'
// @ts-ignore
import { jsx, Box, Flex, Button } from 'theme-ui'
import urls from '~/common/urls'
import { Search } from '~/components/icons/search'
import VisuallyHidden from '@reach/visually-hidden'
import HeaderCartOpener from '~/components/header/header-cart-opener'
import { useCustomerContext } from '~/context/customer-context'

const RightMenuDesktopItem: FC = ({ ...rest }) => (
  <Box
    sx={{
      ml: 'md2',
      overflow: 'hidden',
      position: 'relative',
    }}
    {...rest}
  />
)

type Props = {
  disableSearchOpen?: boolean
  toggleSearch: (value: boolean) => any
  searchActive?: boolean
  color?: string
}

type ButtonEvent = MouseEvent<HTMLButtonElement>

const RightMenuDesktop: FC<Props> = ({ disableSearchOpen, toggleSearch, color }) => {
  const { customer, isLoggedIn } = useCustomerContext()
  const handleSearchOpen = useCallback(
    (e: ButtonEvent) => {
      if (disableSearchOpen) {
        return
      }

      e.preventDefault()
      toggleSearch(true)
    },
    [disableSearchOpen]
  )

  return (
    <Flex sx={{ alignItems: 'center' }}>
      <RightMenuDesktopItem>
        <Button sx={{ textTransform: 'uppercase' }} variant="noStyle2" onClick={handleSearchOpen}>
          <VisuallyHidden>Search</VisuallyHidden>
          <Search color={color} className="search-icon" />
        </Button>
      </RightMenuDesktopItem>
      <RightMenuDesktopItem>
        <Link
          sx={{
            textTransform: 'uppercase',
            fontSize: '16px !important',
            display: 'block',
            maxWidth: ['100%', null, '240px', null, '100%'],
          }}
          to={isLoggedIn ? urls.account.accountDashboard : urls.account.signIn}
          data-cy="account-link"
        >
          {isLoggedIn && customer?.firstName !== undefined ? `${customer?.email}` : 'Account'}
        </Link>
      </RightMenuDesktopItem>
      <RightMenuDesktopItem>
        <HeaderCartOpener sxProps={{ textTransform: 'uppercase', fontSize: '16px !important' }} />
      </RightMenuDesktopItem>
    </Flex>
  )
}

export default RightMenuDesktop
