/** @jsx jsx */

import React, { FC, Fragment } from 'react'
import { jsx, SxStyleProp, Button, Box } from 'theme-ui'
import { useCartContext } from '~/context/cart-context'
import CartOverlay from '~/components/cart-overlay'
import CartOverlayContent from '~/components/cart-overlay/cart-overlay-content'
import { RiShoppingBagLine, RiShoppingCartLine } from '@remixicon/react'
import { ICON_SIZE } from '../constants'

type Props = {
  sxProps?: SxStyleProp
}

const HeaderCartOpener: FC<Props> = ({ sxProps }) => {
  const { totalQuantity, overlayOpen, setOverlayOpen } = useCartContext()
  const openOverlay = () => setOverlayOpen(true)
  const closeOverlay = () => setOverlayOpen(false)
  return (
    <Fragment>
      <Button
        variant="noStyle2"
        className="header-cart-opener"
        onClick={openOverlay}
        sx={{
          position: 'relative',
          paddingLeft: '0px',
          paddingRight: '0px',
          ...sxProps,
        }}
      >
        <RiShoppingBagLine sx={{ width: ICON_SIZE }} />
        {totalQuantity > 0 ? (
          <Box
            sx={{
              fontSize: '9px',
              position: 'absolute',
              // backgroundColor: '#061826',
              color: 'currentColor',
              // borderRadius: '50%',
              top: '10px',
              right: '50%',
              fontWeight: 'bold',
              transform: 'translateX(50%)',
            }}
          >
            {totalQuantity}
          </Box>
        ) : null}
      </Button>
      <CartOverlay showDialog={overlayOpen} close={closeOverlay}>
        <CartOverlayContent closeCartOverlay={closeOverlay} />
      </CartOverlay>
    </Fragment>
  )
}

export default HeaderCartOpener
