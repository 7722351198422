// react-hooks/rules-of-hooks
/* eslint-disable react-hooks/rules-of-hooks */

import { Maybe } from 'graphql/jsutils/Maybe'
import { useCallback, useMemo } from 'react'
import { ProductItemComponentType, ProductVariantType } from '~/@types/models'
import { formatMoney } from '~/utils/format'
import { isValidGroupDiscount } from '~/utils/is-valid-trade-customer-discount'

interface IMountPrices {
  product: Pick<ProductVariantType, 'price' | 'compareAtPrice' | 'discountAllocations'>
  productType: string
  isFabric?: boolean
  isTradeAccount?: boolean
  quantity?: number
  long?: boolean
}

export const RUGS_GROUP = ['CONTEMPORARY RUGS', 'TRADITIONAL RUGS', 'MOROCCAN RUGS']
type TCustomerType = 'retail' | 'trade'

interface IAdditionalPrice {
  productType: string[]
  discountPercentual?: number
  discountPercentualMatch: number
  discountMessage: string
  discountType?: string
}

const activeDiscount: Record<TCustomerType, IAdditionalPrice | null> = {
  // retail: {
  //   productType: RUGS_GROUP,
  //   discountPercentualMatch: 0.8,
  //   discountMessage: '20% OFF RUG SALE | FINAL SALE',
  //   discountType: 'promotional',
  // },
  retail: null,
  trade: {
    productType: RUGS_GROUP,
    discountPercentual: 0.8,
    discountPercentualMatch: 0.75,
    discountMessage: 'TRADE PRICE',
    discountType: 'trade',
  },
}

export function parseValue(price: number, long = false, isFabric = false) {
  if (isFabric) {
    return formatMoney(price).replace('.00', '/YARD')
  }
  return formatMoney(price, 'USD', {
    minimumFractionDigits: !long ? 0 : 2,
    maximumFractionDigits: !long ? 0 : 2,
  })
}

export const useMountPrices = ({
  product,
  productType,
  isFabric,
  isTradeAccount,
  quantity,
  long,
}: IMountPrices) => {
  if (!product) return null

  const price = useMemo(() => (Number(product?.price?.amount) || 0) * (quantity || 1), [
    product?.price?.amount,
    quantity,
  ])

  const customerType = useMemo(() => (isTradeAccount ? 'trade' : 'retail'), [isTradeAccount])

  const calculateDiscount = useCallback(
    (
      amount: number
    ): {
      price: number
      discountMessage?: string | null
      discountType?: string | null
      compareAtPrice?: number | null
    } => {
      if (amount > 0)
        return {
          price,
          compareAtPrice: amount,
          discountMessage: 'SALE',
          discountType: 'sales',
        }

      const discount = activeDiscount[customerType]

      if (discount) {
        const isValidProduct =
          discount.productType.includes(productType) && isValidGroupDiscount(productType)

        if (discount.discountPercentual && !isValidProduct) {
          return {
            price: price * discount.discountPercentual,
            compareAtPrice: price,
            discountMessage: discount.discountMessage,
            discountType: discount.discountType,
          }
        }

        if (isValidProduct)
          return {
            price: price * discount.discountPercentualMatch,
            compareAtPrice: price,
            discountMessage: discount.discountMessage,
            discountType: discount.discountType,
          }
      }

      return { price, compareAtPrice: null, discountMessage: null, discountType: null }
    },
    [customerType, productType, price]
  )

  const { compareAtPrice, discountMessage, discountType, discountPrice } = useMemo(() => {
    const _compareAtPrice = (Number(product?.compareAtPrice?.amount) || 0) * (quantity || 1)
    const {
      price: discountPrice,
      compareAtPrice,
      discountMessage,
      discountType,
    } = calculateDiscount(_compareAtPrice)

    return { compareAtPrice, discountMessage, discountType, discountPrice }
  }, [product?.compareAtPrice?.amount, quantity, price])

  return {
    price: discountPrice,
    priceFormatted: parseValue(discountPrice, long, isFabric),
    compareAtPrice,
    compareAtPriceFormatted: compareAtPrice ? parseValue(compareAtPrice, long, isFabric) : null,
    discountMessage,
    discountType,
  }
}

export interface IRangePrices {
  product: ProductItemComponentType
  productVariant: Maybe<ProductVariantType>
  isFabric?: boolean
  isTradeAccount?: boolean
  quantity?: number
  long?: boolean
}

export const useMountRangePrices = ({
  product,
  productVariant,
  isFabric,
  isTradeAccount,
  quantity,
  long,
}: IRangePrices) => {
  const cheapProduct = productVariant || product?.cheapestVariant || product
  const highProduct = product?.highestVariant || null

  const cheapPrice = useMountPrices({
    product: cheapProduct,
    productType: product?.productType || '',
    isFabric,
    isTradeAccount,
    quantity,
    long,
  })

  const highPrice = useMountPrices({
    product: highProduct,
    productType: product?.productType || '',
    isFabric,
    isTradeAccount,
    quantity,
    long,
  })

  return {
    cheapPrice,
    highPrice,
  }
}
