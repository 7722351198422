import styled from 'styled-components'

export const StyledHeaderWrapper = styled.div`
  display: contents;

  .affix-active {
    z-index: 999;
    top: 0 !important;
    #header-wrapper-mobile {
      position: relative;
    }
  }
`
