type Props = {
  isTradeCustomer: boolean
  productType: string
}

const EXCLUDED_PRODUCT_TYPES_FROM_TRADE_CUSTOMER_DISCOUNT = [
  'Books',
  'Gift Card',
  '$100 USD',
  '$300 USD',
  '$500 USD',
  '$1000 USD',
]

// We have in production a Script Editor app injecting discounts.
// We also need to updated there in order to make it work.
// https://admin.shopify.com/store/bensoleimani/apps/script-editor/scripts/182353984
export const isInvalidTradeDiscountProduct = (productType: string) => {
  for (const excluded of EXCLUDED_PRODUCT_TYPES_FROM_TRADE_CUSTOMER_DISCOUNT) {
    if (productType === excluded) {
      return true
    }
  }

  return false
}

export const isValidTradeCustomerDiscount = ({ isTradeCustomer, productType }: Props): boolean => {
  return Boolean(isTradeCustomer && productType && !isInvalidTradeDiscountProduct(productType))
}
export const isValidGroupDiscount = (productType: string): boolean => {
  return Boolean(productType && !isInvalidTradeDiscountProduct(productType))
}
