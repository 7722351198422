import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { IMenu, useMenu } from '~/context/menu-context'

interface MenuContextType {
  currentMenu: IMenu | null
  previousMenu: IMenu | null
  footerMenu: IMenu[] | null
  setCurrentMenu: (menu: IMenu | null) => void
}

const MenuContext = createContext<MenuContextType | undefined>(undefined)

const filterMenusWithMobileImage = (menu: IMenu): IMenu | null => {
  const filteredSubMenus = (menu.menus || []).flatMap(subMenu => {
    if (subMenu.groupName) {
      subMenu['name'] = subMenu.groupName

      if (subMenu.mobileImage) {
        return [subMenu]
      }

      return (subMenu.menus || [])
        .map(innerMenu => filterMenusWithMobileImage(innerMenu))
        .filter(Boolean) as IMenu[]
    }

    return [filterMenusWithMobileImage(subMenu)].filter(Boolean) as IMenu[]
  })

  if (menu.mobileImage || filteredSubMenus.length > 0) {
    return {
      ...menu,
      menus: filteredSubMenus,
    }
  }

  return null
}

const findParentMenu = (currentMenu: IMenu, targetId?: number): IMenu | null => {
  if (!currentMenu?.menus || !targetId) return null

  if (currentMenu.menus?.find(subMenu => subMenu.id === targetId)) {
    return currentMenu
  }

  for (const subMenu of currentMenu.menus) {
    const found = findParentMenu(subMenu, targetId)
    if (found) return found
  }
  return null
}

export const MenuMobileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { dynamicMenu } = useMenu()

  const mobileMenu = useMemo(() => {
    return {
      menus: [
        dynamicMenu.topMenuLeft,
        ...dynamicMenu.menus.map(menu => filterMenusWithMobileImage(menu)).filter(Boolean),
      ],
    }
  }, [dynamicMenu])

  const footerMenu = useMemo(() => dynamicMenu.menus.filter(menu => menu.showMobileFooter) || [], [
    dynamicMenu,
  ])
  const [currentMenu, setCurrentMenu] = useState<IMenu | null>(mobileMenu)

  const previousMenu = useMemo(() => {
    if (!currentMenu?.id) return null

    return findParentMenu(mobileMenu, currentMenu.id)
  }, [currentMenu, mobileMenu])

  useEffect(() => {
    setCurrentMenu(mobileMenu)
  }, [mobileMenu])

  return (
    <MenuContext.Provider value={{ currentMenu, setCurrentMenu, footerMenu, previousMenu }}>
      {children}
    </MenuContext.Provider>
  )
}

export const useMenuContext = () => {
  const context = useContext(MenuContext)
  if (context === undefined) {
    throw new Error('useMenuContext must be used within a MenuProvider')
  }
  return context
}
